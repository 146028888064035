













































import Vue from 'vue'
import AppHeaderTokenStats, { DIRECTIONS as STAT_DIRECTIONS } from './AppHeaderTokenStats.vue'
import AppHeaderAccount from './AppHeaderAccount.vue'
import { TokenStatistic, STAT_STATUSES } from '@/models/statistic'

export default Vue.extend({
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },

  data(): {
    statMenuOpen: boolean
  } {
    return {
      statMenuOpen: false
    }
  },

  components: {
    AppHeaderTokenStats,
    AppHeaderAccount
  },

  computed: {
    STAT_DIRECTIONS() {
      return STAT_DIRECTIONS
    },

    tokenStatDumpDatas(): TokenStatistic[] {
      return [
        {
          label: 'btc/usd',
          price: 48641,
          rate: 6.15,
          status: STAT_STATUSES.DOWN
        },
        {
          label: 'eth/usd',
          price: 3277,
          rate: 6.86,
          status: STAT_STATUSES.UP
        }
      ]
    }
  },

  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar', !this.mini)
    },

    toggleStatMenu() {
      this.statMenuOpen = !this.statMenuOpen
    }
  }
})
