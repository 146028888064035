





























































import Vue from 'vue'
import AppHeaderBalance from './AppHeaderBalance.vue'
import { mapState, mapMutations } from 'vuex'
import StorageKeys from '@/constants/storage-keys'
import { AUTH_MUTATION_TYPES } from '@/store/modules/auth/types'
import AuthService from '@/services/auth'

export default Vue.extend({
  data(): {
    profileMenuOpen: boolean
    balanceVisible: boolean
    balanceMenuOpen: boolean
  } {
    return {
      profileMenuOpen: false,
      balanceVisible: false,
      balanceMenuOpen: false
    }
  },

  components: {
    AppHeaderBalance
  },

  computed: {
    ...mapState('auth', ['user']),

    userName(): string {
      if (this.user) {
        return this.user.email.split('@')[0]
      }

      return 'Account'
    }
  },

  methods: {
    ...mapMutations('auth', [AUTH_MUTATION_TYPES.REMOVE_CURRENT_USER]),

    toggleProfileMenu() {
      this.profileMenuOpen = !this.profileMenuOpen
    },

    toggleVisibleBalance() {
      this.balanceVisible = !this.balanceVisible
    },

    toggleBalanceMenu() {
      this.balanceMenuOpen = !this.balanceMenuOpen
    },

    async logout() {
      await AuthService.logout()

      // remove data
      localStorage.removeItem(StorageKeys.authProfile)
      ;(this as any).REMOVE_CURRENT_USER()
      // redirect to login page
      this.$router.push({ name: 'login' })
    }
  }
})
